export const languageCodeList = ['nl', 'de', 'en', 'fr', 'pl'] as const;

export type LanguageCode = (typeof languageCodeList)[number];

export const languageLabel = new Map<LanguageCode, string>([
  ['nl', 'Nederlands'],
  ['de', 'Deutsch'],
  ['en', 'English'],
  ['fr', 'Français'],
  ['pl', 'Polski'],
]);

export type Language = {
  code: LanguageCode;
  label: string;
  image: string;
};
