import { Organisation } from '@svnl/shared';

export enum PermitType {
  KVP = 'KVP',
  VPS = 'VPS',
}

export class RequiredDocument {
  organisation: Organisation;
  permitType: PermitType;
  showInPopup: boolean;
}

export type RequiredDocumentLabelType = 'default' | 'short';
