import { Injectable, inject } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { alertOutline } from 'ionicons/icons';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastController = inject(ToastController);
  private currentToast: HTMLIonToastElement;

  constructor() {
    addIcons({ alertOutline });
  }

  async warning(message: string): Promise<void> {
    const options: ToastOptions = {
      color: 'warning',
      duration: 4000,
      icon: 'alert-outline',
    };
    await this.toast(message, options);
  }

  async error(message: string): Promise<void> {
    const options: ToastOptions = {
      color: 'danger',
    };
    await this.toast(message, options);
  }

  private async toast(message: string, options?: ToastOptions): Promise<void> {
    this.currentToast?.dismiss();

    const defaultOptions: ToastOptions = {
      position: 'bottom',
      duration: 3000,
    };

    const toastOptions: ToastOptions = {
      ...defaultOptions,
      ...options,
      message,
    };

    const toast = await this.toastController.create(toastOptions);

    await toast.present();

    this.currentToast = toast;
  }
}
