import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Browser } from '@capacitor/browser';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonText,
  IonTitle,
  IonToolbar,
  MenuController,
} from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { addIcons } from 'ionicons';
import {
  chevronDown,
  close,
  help,
  map,
  person,
  settings,
  water,
} from 'ionicons/icons';

type SubPage = {
  title: string;
  url: string;
};

type MainPage = SubPage & {
  icon: string;
};

@Component({
  selector: 'svvp-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
  standalone: true,
  imports: [
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonMenuToggle,
    IonItem,
    IonLabel,
    IonText,
    IonFooter,
    RouterLink,
    TranslateModule,
  ],
})
export class MainMenuComponent {
  mainPages: MainPage[] = [];
  subPages: SubPage[] = [];
  menuId: 'main-content';

  private menuController = inject(MenuController);

  constructor() {
    addIcons({
      chevronDown,
      close,
      help,
      map,
      person,
      water,
      settings,
    });
    this.buildMenu();
  }

  async openUrl(url: string): Promise<void> {
    await Browser.open({ url });
  }

  buildMenu(): void {
    this.mainPages = [
      {
        title: 'mainMenu.link.map',
        url: '/map',
        icon: 'map',
      },
      {
        title: 'mainMenu.link.waters',
        url: '/waters',
        icon: 'water',
      },
      {
        title: 'mainMenu.link.profile',
        url: '/profile',
        icon: 'person',
      },
      {
        title: 'mainMenu.link.help',
        url: '/help',
        icon: 'help',
      },
      {
        title: 'mainMenu.link.settings',
        url: '/settings',
        icon: 'settings',
      },
    ];

    this.subPages = [
      {
        title: 'mainMenu.link.about',
        url: '/about',
      },
      {
        title: 'mainMenu.link.conditions',
        url: '/conditions',
      },
      {
        title: 'mainMenu.link.behavioral',
        url: '/code-of-conduct',
      },
      // {
      //   title: "mainMenu.link.other",
      //   url: "/other-apps",
      // },
    ];
  }

  close(): void {
    this.menuController.close(this.menuId);
  }
}
