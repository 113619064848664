import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  NgZone,
} from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideRouter, Router, RouteReuseStrategy } from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { createErrorHandler } from '@sentry/angular-ivy';
import { AnalyticsService } from '@service/analytics.service';
import { AppInitializeService } from '@service/app-initialize.service';
import { ErrorService } from '@service/error.service';
import { NetworkService } from '@service/network.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { catchError, Observable, of } from 'rxjs';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { TokenInterceptor } from './app/interceptor/token.interceptor';
import { KeycloakService } from './app/keycloak/service/keycloak.service';
import { KeycloakStateStore } from './app/keycloak/storage/keycloak.storage';
import { environment } from './environments/environment';

const initializeKeycloakService = (
  networkService: NetworkService,
  keycloakService: KeycloakService,
) => {
  return async (): Promise<void> => {
    await networkService.init().then(() => keycloakService.init());
  };
};
const analyticsServiceInitializerFactory = (
  analyticsService: AnalyticsService,
) => {
  return (): Observable<void> => analyticsService.initialize();
};
const errorServiceInitializerFactory = (errorService: ErrorService) => {
  return (): Observable<void> => errorService.initialize();
};
const appInitializer = (appInitializeService: AppInitializeService) => {
  return (): Observable<boolean> =>
    appInitializeService.initialize().pipe(catchError(() => of(false)));
};
const httpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      GoogleTagManagerModule.forRoot({
        id: environment?.analytics?.google.gtm,
      }),
    ),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloakService,
      multi: true,
      deps: [
        NetworkService,
        KeycloakService,
        Router,
        KeycloakStateStore,
        NgZone,
      ],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: errorServiceInitializerFactory,
      deps: [ErrorService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({ showDialog: false }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AppInitializeService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: analyticsServiceInitializerFactory,
      deps: [AnalyticsService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    provideIonicAngular(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch(err => console.error(err));
