import { Injectable } from '@angular/core';
import { SecureStorageService } from '@service/secure-storage.service';
import { WebStorageStateStore } from 'oidc-client-ts';

@Injectable({
  providedIn: 'root',
})
export class KeycloakStateStore extends WebStorageStateStore {
  constructor(private secureStorage: SecureStorageService) {
    super({ prefix: 'visplanner', store: secureStorage });
  }

  override async get(key: string): Promise<string | null> {
    console.log(`Trying to retrieve key from storage: ${key}`);

    return this.secureStorage
      .getItem(key)
      .then(item => {
        console.log(`Got item from secure storage : ${item}`);

        return item;
      })
      .catch(error => {
        console.log(`Got error from secure storage : ${error}`);

        return null;
      });
  }

  override async getAllKeys(): Promise<string[]> {
    return this.secureStorage.keys();
  }

  override async remove(key: string): Promise<string | null> {
    return this.secureStorage.removeItem(key).then(() => null);
  }

  override async set(key: string, value: string): Promise<void> {
    console.log(`Storing item in secure storage : ${key} : ${value}`);

    return this.secureStorage.setItem(key, value);
  }
}
