import Color from '@arcgis/core/Color';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import { ActionButtonType } from '@component/action-button/action-button.component';
import { LanguageCode } from '@model/language';
import { MapFilter, MapFilterType } from '@model/mapfilter.model';
import { PdfAssetType } from '@service/pdf-asset-type';

export class Constants {
  private static readonly DOWNLOAD_PREFIX = '/assets/downloads/';

  static readonly DATABASE_NAME = 'visplanner';

  static readonly SETTINGS_ID = 1;

  static readonly DEFAULT_LANGUAGE_CODE: LanguageCode = 'nl';

  static readonly DOCUMENT_ID_NIGHT_FISHING = 5;
  static readonly DOCUMENT_ID_THIRD_ROD = 6;

  static readonly MAP_INITIAL_ZOOM_LEVEL = 6;
  static readonly MAP_LOCATION_ZOOM_LEVEL = 13;
  static readonly STREET_ZOOM_WARNING_LEVEL = 4;
  static readonly SATTELITE_ZOOM_WARNING_LEVEL = 11;

  static readonly PDF_ASSETS: Map<PdfAssetType, string> = new Map([
    ['waterList', `${Constants.DOWNLOAD_PREFIX}Sportvisserij_GZL.pdf`],
    [
      'waterListSmall',
      `${Constants.DOWNLOAD_PREFIX}Sportvisserij_Kleine_Lijst.pdf`,
    ],
    [
      'additionalList',
      `${Constants.DOWNLOAD_PREFIX}Sportvisserij_Aanvullingslijst.pdf`,
    ],
  ]);

  /** Arcade / ArcGIS queries **/
  private static readonly WATER_NAME_FILTER_QUERY = `id is not null`;
  private static readonly WATER_PUBLISH_DATE_FILTER_QUERY = `publicatiedatum is not null and publicatiedatum < CURRENT_TIMESTAMP`;
  private static readonly WATER_END_DATE_FILTER_QUERY = `(einddatum is null or einddatum > CURRENT_TIMESTAMP)`;
  static readonly DEFAULT_WATER_FILTER_QUERY = `${Constants.WATER_NAME_FILTER_QUERY} and ${Constants.WATER_PUBLISH_DATE_FILTER_QUERY} and ${Constants.WATER_END_DATE_FILTER_QUERY}`;

  static readonly ALLOWED_WATER_VALUE = 'ALLOWED_WATER';
  static readonly DISALLOWED_WATER_VALUE = 'DISALLOWED_WATER';

  static readonly FEATURE_NIGHT_FISHING = `$feature.nachtvissen`;
  static readonly FEATURE_THIRD_ROD = `$feature.derdehengel`;
  static readonly FEATURE_OVERNIGHT_STAY = `$feature.nachtverblijf`;

  static readonly FEATURE_ORGANISATION = `$feature.organisatie`;
  static readonly FEATURE_PUBLIC_WATER = `$feature.landelijkelijst`;
  static readonly FEATURE_SMALL_PERMIT_WATER = `$feature.kleinelijst`;
  static readonly FEATURE_OFFSHORE = `$feature.buitengaats`;
  static readonly FEATURE_EXCHANGE = `$feature.uitwisselingen`;

  static readonly BEGIN_ARCADE_QUERY = `When(`;
  static readonly END_ARCADE_QUERY = `)`;

  static readonly IS_PUBLIC_WATER_QUERY = `${this.FEATURE_PUBLIC_WATER} == 1 || ${this.FEATURE_OFFSHORE} == 1, '${this.ALLOWED_WATER_VALUE}', '${this.DISALLOWED_WATER_VALUE}'`;
  static readonly IS_SMALL_PERMIT_WATER_QUERY = `${this.FEATURE_SMALL_PERMIT_WATER} == 1 || ${this.FEATURE_OFFSHORE} == 1, '${this.ALLOWED_WATER_VALUE}', '${this.DISALLOWED_WATER_VALUE}'`;
  static readonly NO_ALLOWED_WATERS_QUERY: string = `${this.FEATURE_PUBLIC_WATER} == 1, '${this.DISALLOWED_WATER_VALUE}', '${this.DISALLOWED_WATER_VALUE}'`;

  static readonly SVNL_EMAIL_TO = 'mailto:visplanner@sportvisserijnederland.nl';
  static readonly SVNL_URL_FAQ =
    'https://www.sportvisserijnederland.nl/over-ons/veelgestelde-vragen';
  static readonly SVNL_URL_TERMS_AND_CONDITIONS =
    'https://www.vispas.nl/termsconditions';
  static readonly SVNL_URL_TERMS_AND_CONDITIONS_SMALL =
    'https://www.sportvisserijnederland.nl/vispas/kleine-vispas/algemene-voorwaarden.html';
  static readonly SVNL_URL_VISPAS = 'https://www.vispas.nl';
  static readonly SVNL_URL_MIJN_SPORTVISSERIJ =
    'https://www.mijnsportvisserij.nl';
  static readonly SVNL_URL_FISHING_IN_HOLLAND =
    'https://www.fishinginholland.nl';
  static readonly SVNL_URL_BEHAVIORAL = 'https://www.vispas.nl/behavioral';
  static readonly SVNL_URL_PDF_DOWNLOADS =
    'https://www.sportvisserijnederland.nl/vispas/lijsten-van-wateren';

  static readonly NIGHT_FISHING_MAP_FILTER_ID = 1;
  static readonly THIRD_ROD_MAP_FILTER_ID = 2;
  static readonly OVERNIGHT_STAY_MAP_FILTER_ID = 3;
  static readonly SMALL_PERMIT_MAP_FILTER_ID = 4;

  static readonly MAP_FILTERS: MapFilter[] = [
    {
      id: Constants.NIGHT_FISHING_MAP_FILTER_ID,
      label: 'map.filter.label.nightFishing',
      selected: false,
      icon: '/assets/icon/nachtvissen.svg',
      type: MapFilterType.WATER,
      value: 'nachtvissen',
    },
    {
      id: Constants.THIRD_ROD_MAP_FILTER_ID,
      label: 'map.filter.label.thirdRod',
      selected: false,
      icon: '/assets/icon/derde_hengel.svg',
      type: MapFilterType.WATER,
      value: 'derdehengel',
    },
    {
      id: Constants.OVERNIGHT_STAY_MAP_FILTER_ID,
      label: 'map.filter.label.overnightStay',
      selected: false,
      icon: '/assets/icon/nachtverblijf.svg',
      type: MapFilterType.WATER,
      value: 'nachtverblijf',
    },
    {
      id: Constants.SMALL_PERMIT_MAP_FILTER_ID,
      label: 'map.filter.label.smallPermitList',
      selected: false,
      icon: '/assets/icon/kleineVispas.svg',
      type: MapFilterType.WATER,
      value: 'kleineLijst',
    },
    {
      id: 5,
      label: 'map.filter.label.pier',
      selected: false,
      icon: '/assets/icon/vissteiger.svg',
      type: MapFilterType.POI,
      value: 2,
    },
    {
      id: 6,
      label: 'map.filter.label.modifiedSpot',
      selected: false,
      icon: '/assets/icon/aangepast.svg',
      type: MapFilterType.POI,
      value: 3,
    },
    {
      id: 7,
      label: 'map.filter.label.trailerRamp',
      selected: false,
      icon: '/assets/icon/trailerhelling.svg',
      type: MapFilterType.POI,
      value: 4,
    },
  ];
  static readonly ACTION_BUTTON_TYPE_ICON = new Map<ActionButtonType, string>([
    ['link', 'open-outline'],
    ['email', 'mail-outline'],
    ['pdf', 'document-text-outline'],
    ['info', 'information-circle-outline'],
  ]);

  static readonly PRIMARY_COLOR_MARKER_SYMBOL = new SimpleMarkerSymbol({
    color: new Color([0, 104, 181]),
    outline: new SimpleLineSymbol({ color: [255, 255, 255] }),
  });

  static readonly PROJ_4_RIJKSDRIEHOEK =
    '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +units=m +no_defs';
}
