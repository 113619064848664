import { Routes } from '@angular/router';
import { skipStartGuard } from '@service/skip-start-guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full',
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.routes').then(m => m.routes),
  },
  {
    path: 'waters',
    loadChildren: () => import('./waters/waters.routes').then(m => m.routes),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.routes').then(m => m.routes),
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.routes').then(m => m.routes),
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.routes').then(m => m.routes),
  },
  {
    path: 'conditions',
    loadChildren: () =>
      import('./conditions/conditions.routes').then(m => m.routes),
  },
  {
    path: 'code-of-conduct',
    loadChildren: () =>
      import('./code-of-conduct/code-of-conduct.routes').then(m => m.routes),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.routes').then(m => m.routes),
  },
  // {
  //   path: 'other-apps',
  //   loadChildren: () =>
  //     import('./other-apps/other-apps.routes').then(m => m.routes),
  // },
  {
    path: 'start',
    canActivate: [skipStartGuard],
    loadChildren: () => import('./start/start.routes').then(m => m.routes),
  },
  {
    path: 'water',
    loadChildren: () =>
      import('./modals/water/water.routes').then(m => m.routes),
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.routes').then(m => m.routes),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];
