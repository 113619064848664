export enum MapFilterType {
  POI = 'locatiepunttype',
  WATER = 'water',
}

export type MapFilter = {
  label: string;
  id: number;
  selected: boolean;
  disabled?: boolean;
  type: MapFilterType;
  icon: string;
  value: string | number;
};
