<ion-menu
  contentId="main-content"
  type="overlay"
  side="end"
  swipeGesture="false"
>
  <ion-header>
    <ion-toolbar color="tertiary">
      <ion-title>{{ 'mainMenu.title' | translate }}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-list>
      <ion-menu-toggle auto-hide="false">
        @for (mainPage of mainPages; track mainPage.url) {
          <ion-item
            routerDirection="root"
            [routerLink]="mainPage.url"
            lines="none"
            detail="false"
          >
            <ion-icon
              color="primary"
              slot="start"
              [name]="mainPage.icon"
            ></ion-icon>
            <ion-label color="secondary"
              >{{ mainPage.title | translate }}
            </ion-label>
          </ion-item>
        }

        @for (subPage of subPages; track subPage.url) {
          <ion-item
            routerDirection="root"
            [routerLink]="subPage.url"
            lines="none"
            detail="false"
          >
            <ion-text color="secondary"
              >{{ subPage.title | translate }}
            </ion-text>
          </ion-item>
        }
      </ion-menu-toggle>
    </ion-list>
  </ion-content>
  <ion-footer class="ion-text-center">
    <img
      src="/assets/img/menu-logo-white.png"
      alt="Logo Sportvisserij Nederland"
    />
  </ion-footer>
</ion-menu>
