import { Injectable } from '@angular/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { AsyncStorage } from 'oidc-client-ts';

@Injectable({
  providedIn: 'root',
})
export class SecureStorageService implements AsyncStorage {
  constructor() {
    console.debug('SecureStorageService initiated');
  }

  readonly length: Promise<number>;

  async clear(): Promise<void> {
    try {
      await SecureStoragePlugin.clear();

      return Promise.resolve();
    } catch (error) {
      console.error(`Error clearing storage: ${error}`);

      return Promise.resolve(void 0);
    }
  }

  async getItem(key: string): Promise<string | null> {
    try {
      const item = await SecureStoragePlugin.get({ key });
      if (item) {
        return item.value;
      }

      return null;
    } catch (error) {
      console.error(`Error getting item '${key}': ${JSON.stringify(error)}`);

      return Promise.resolve(null);
    }
  }

  async key(index: number): Promise<string | null> {
    const keys = await this.keys();

    if (keys[index]) {
      return Promise.resolve(keys[index]);
    }
    console.error(`No item found at index : ${index}`);

    return Promise.resolve(null);
  }

  async keys(): Promise<string[]> {
    try {
      return (await SecureStoragePlugin.keys()).value;
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  async removeItem(key: string): Promise<void> {
    try {
      await SecureStoragePlugin.remove({ key });

      return Promise.resolve();
    } catch (error) {
      console.error(`Error removing item with key ${key}: ${error}`);

      return Promise.resolve(void 0);
    }
  }

  async setItem(key: string, value: string): Promise<void> {
    try {
      await SecureStoragePlugin.set({ key, value });

      return Promise.resolve();
    } catch (error) {
      console.error(`Error setting item with key ${key}: ${error}`);

      return Promise.resolve(void 0);
    }
  }
}
